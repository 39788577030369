<template>
  <v-container>
    <h1>Frequently Asked Questions</h1>

    <v-card class="mb-10">
      <v-card-title>
        What information do you collect?
      </v-card-title>
      <v-card-text>
        <p>
          In order to process your resignation we will need to collect some personal information. Your
          information is stored securely, and is only used in conjunction with your resignation. We will
          not spam you or sell your information. We do not use our data for advertising or marketing purposes. We may
          occasionally email you from time to time regarding things we think you may be interested (class action law
          suits, etc.) but you can opt-out at any time. Historically, we've sent emails like this about 1-2 times per
          year.
        </p>
        <v-simple-table>
          <tr>
            <th>Data Collected</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td>
              Full name
            </td>
            <td>
              locating your church records
            </td>
          </tr>
          <tr>
            <td>
              Birth date
            </td>
            <td>
              used to locate and verify your membership record
            </td>
          </tr>
          <tr>
            <td>
              Address
            </td>
            <td>
              used to locate and verify your membership record
            </td>
          </tr>
          <tr>
            <td>
              Membership number
            </td>
            <td>
              If you know it, providing your membership number can assist in
              locating your membership record, but is <span class="text-bold">NOT</span> required
            </td>
          </tr>
          <tr>
            <td>
              Email address
            </td>
            <td>
              communication about your resignation status
            </td>
          </tr>
        </v-simple-table>

      </v-card-text>
    </v-card>

    <v-card class="mb-10">
      <v-card-title>
        Minors and Children
      </v-card-title>
      <v-card-text>
        <p>
          If you are a minor, you will need signatures from both of parents. If your parents are divorced, and the
          divorce decree or custody agreement specifically allows one parent to make religious decisions for you, or if
          one parent is deceased, you may resign with one parent's signature if you can also provide supporting
          documentation.
        </p>


        <router-link :to="{ name: 'minor-children' }">More information on minors</router-link>

      </v-card-text>
    </v-card>


    <v-card class="mb-10">
      <v-card-title>
        Can I pay you for this service?
      </v-card-title>
      <v-card-text>
        <p>We do not accept payment for this service, however we are now accepting donations to fund the costs
          associated
          with processing resignations.</p>
        <v-btn color="primary"
               href="/donate">Donate to QuitMormon
        </v-btn>
      </v-card-text>
    </v-card>


    <v-card class="mb-10">
      <v-card-title>
        Is my personal information shared with anyone?
      </v-card-title>
      <v-card-text>

        <p>
          Your personal information is only used to process your resignation. The information on your resignation form will be shared with Kirton McConkie, the church's law firm, who needs this information
          in order to locate your records and confirm that they have identified the correct person.
        </p>

        <p>
          We may occasionally provide anonymous statistics to the public or press based on number of people resigning in a given time period, or location
          (country, state, postal code), possibly grouped by gender or age buckets (0-10, 10-20, etc). We never share names, emails, exact ages, IP addresses, or anything that could be considered personally identifiable information (PII).
        </p>

        <v-alert border="left"
                 class="text-bold"
                 outlined
                 text
                 type="warning">
          The exception to this is if you use our service to attempt to resign another person for which that person did
          not authorize you to do so, OR if you use our service to make threats against us or any other person, we will
          share your information with law enforcement. This includes the president, deceased people, celebrities, church
          leaders, your friends (pranks), revenge, etc.
        </v-alert>
      </v-card-text>
    </v-card>


    <v-card class="mb-10">
      <v-card-title>
        How long does the resignation process take?
      </v-card-title>
      <v-card-text>
        <p>We usually upload your resignation letter to Kirton McConkie within a few days to a few weeks after you have provided all the necessary
          information to us. The church typically processes and returns the confirmation to us within 4-6 weeks after
          we've uploaded it to them.
        </p>

        <p>
          Our resignation form states that we will consider your resignation accepted automatically if we do not hear
          back from the church within a reasonable time period. Due to a lack of timely response from the church's law
          firm, we may automatically update your resignation to "confirmed" even if we have not heard back from them.
        </p>

        <p>
          We believe this is reasonable, because technically your membership ends as soon as your notarized document is
          submitted to church headquarters, but this still allows some time for them to notify us if they cannot find
          the record, or decide to reject it (usually due to missing information, like signatures or notary stamps)
        </p>
      </v-card-text>
    </v-card>

    <v-card class="mb-10">
      <v-card-title>
        Will my parents / family find out about my resignation?
      </v-card-title>
      <v-card-text>
        <p>If it is critical that no one find out that you have resigned, the safest bet is to delay your resignation.
          Please
          see&nbsp;<a href="https://support.quitmormon.com/kb/a3/privacy-questions-will-my-family-be-notified-when-my-resignation-is-processed.aspx"
                      target="_blank">here</a> for more information.</p>
      </v-card-text>
    </v-card>

    <v-card class="mb-10">
      <v-card-title>
        Is this service available world wide?
      </v-card-title>
      <v-card-text>
        <p>Yes, we can provide this service to anyone in the world.</p>
        <p>Some countries may have different processes or names for the notary service.</p>
      </v-card-text>
    </v-card>

    <v-card class="mb-10">
      <v-card-title>
        I've already submitted a request to my local leadership. Can I submit it again through QuitMormon?
      </v-card-title>
      <v-card-text>
        <p>Yes.</p>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'Faq',
};
</script>

<style scoped>

</style>
